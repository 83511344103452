html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.webviewer {
  /*-webkit-box-shadow: 1px 1px 10px #999;*/
  /*        box-shadow: 1px 1px 10px #999;*/
  height: 100%;
  /*margin: 1em;*/
}

/*.window{*/
/*  display: flex;*/
/*  flex-direction: row;*/
/*  height: 100vh;*/
/*}*/

/*.approvals{*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  width: 15vw;*/
/*  padding: 5px;*/
/*}*/