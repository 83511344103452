.App {
  width: 100%;
  height: 100%;
}

.import-pdf-page {
  /*transform: scale(5, 5)*/
  height: 100%;
  width: 100%;
}

.import-pdf-container {
  height: 200vh;
  width: 200vh;
}

/*.App .header {*/
/*  width: 100%;*/
/*  height: 60px;*/
/*  background-image: linear-gradient(to left, rgba(255, 251, 0, 0) 5%, rgb(14, 13, 1));*/
/*  color: rgb(253, 249, 0);*/
/*  font-size: 2rem;*/
/*  display: flex;*/
/*  flex-direction: row;*/
/*  align-items: center;*/
/*  padding-left: 20px;*/
/*}*/

.full-height-div {
  height: 100%;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-bottom: 2em;
}

.status-container a {
  font-weight: bold;
}

.nb {
  border: none !important;
}

.pdf-button-container {
  position: absolute;
  top: 0.3rem;
  right: 0.25rem;
}

.pdf-button-container button {
  margin-right: 0.25rem;
  padding: 0.1rem 0.5rem !important;
}

/* Scrollbar */
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}